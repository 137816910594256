import React from 'react';
import { GatsbyImage} from 'gatsby-plugin-image';
import Link from 'gatsby-link';

// import SubscriptionForm from 'components/Subscribe';
import Event from './Event';
import * as styles from './styles.module.scss';

import { getSortedEvents } from 'utils/events';

export default class Events extends React.Component {
  render() {
    const events = getSortedEvents(),
      eventComponents = [];

    if (!events || events.length === 0) {
      eventComponents.push(<div className={styles.noEvents} key="no-events">
        <span>Our upcoming events haven't been announced yet. But we have exciting plans in the works. Check back soon!</span>
        <span>Can't wait? <Link to="/contact/" className={styles.link}>Send us a note!</Link></span>
      </div>);
    }

    events.forEach((evt, i) => {
      const { date, url, name, location } = evt;
      eventComponents.push(<Event key={`event-${i}`} name={name} date={date} url={url} location={location} />);
    });

    return (
      <div className={styles.root}>
        <div className={styles.titleWrapper}>
          <GatsbyImage className={styles.img} image={this.props.groupImg} alt={"Performance at JP's Lantern Parade"}/>
          <h1>Events</h1>
        </div>

        <div className={styles.events}>
          <div className={styles.callOut}>
            <p className={styles.highlight}>Join us at our next event</p>
          </div>
          {eventComponents}
        </div>

        {/*<SubscriptionForm text="Get the scoop from Stonybrook Strings in your inbox:" />*/}
      </div>
    );
  }
}
