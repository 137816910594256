// extracted by mini-css-extract-plugin
export var root = "styles-module--root--3lv9_";
export var highlight = "styles-module--highlight--34vfo";
export var events = "styles-module--events--3iOtM";
export var noEvents = "styles-module--noEvents--32eQ2";
export var link = "styles-module--link--3YgYF";
export var callOut = "styles-module--callOut--3RYcg";
export var bold = "styles-module--bold--2RiMD";
export var button = "styles-module--button--3dWHS";
export var titleWrapper = "styles-module--titleWrapper--3fJlA";
export var img = "styles-module--img--3e5zK";
export var noteCard = "styles-module--noteCard--30Yda";
export var noteTitle = "styles-module--noteTitle--31_O6";
export var noteImg = "styles-module--noteImg--2pR1a";