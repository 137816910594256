import React from 'react';
import moment from 'moment';

import * as styles from './styles.module.scss';

export default class Event extends React.Component {
  render() {
    const { date, url, name, location } = this.props,
      classList = [styles.root],
      dateParsed = moment(date, 'YYYY-MM-DD HH:mm'),
      month = dateParsed.format("MMM"),
      day = dateParsed.format("DD"),
      weekday = dateParsed.format("dddd"),
      time = dateParsed.format("h:mm A");

    this.props.className && classList.push(this.props.className);

    return (
      <a className={classList.join(' ')} href={url} target="_blank" rel="noopener noreferrer">
        <div className={styles.date}>
            <div className={styles.month}>{month}</div>
            <div className={styles.day}>{day}</div>
        </div>
        <div className={styles.details}>
            <div className={styles.eventName}>{name}</div>
            <div className={styles.time}>{weekday}, {time}</div>
        </div>
        <div className={styles.location}>
            <div className={styles.line1}>{location[0]}</div>
            <div className={styles.line2}>{location[1]}</div>
        </div>
      </a>
    );
  }
}
