import React from 'react';
import { graphql } from 'gatsby'
import Events from 'components/Events';
import Layout from 'components/Layout';

export default class EventsPage extends React.Component {
  render() {
    return <Layout
      location={this.props.location}
      title={"Events & Performances | Stonybrook Strings"}
      description={"Students at Stonybrook Strings perform across Greater Boston throughout the year. Join as at our next event!"}
    >
      <Events groupImg={this.props.data.groupImg.childImageSharp.gatsbyImageData} />
    </Layout>;
  }
}

export const query = graphql`
  query EventsPageQuery {
    groupImg: file(relativePath: { eq: "lanterns.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout:CONSTRAINED, width: 1000, height: 700)
      }
    }
  }
`;
