// extracted by mini-css-extract-plugin
export var root = "styles-module--root--23vLg";
export var date = "styles-module--date--259_6";
export var day = "styles-module--day--2h4lp";
export var month = "styles-module--month--2v_pi";
export var details = "styles-module--details--2DZsV";
export var eventName = "styles-module--eventName--ow7Np";
export var time = "styles-module--time--2ncWf";
export var location = "styles-module--location--1DICt";
export var line1 = "styles-module--line1--OkrP3";
export var line2 = "styles-module--line2--HqKCY";